import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { clearExistingGameData, setGameMetadata, setGameSequence, setGameStage } from '../utils/utils';
import ResetGameButton from '../components/ResetGameButton';

function ScenarioSelectPage() {
  const [scenarios, setScenarios] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    try {
      async function loadScenarioList() {
        // load list of game scenarios
        const scenarioResponse = await fetch('/config/all_scenarios.json');
        const allScenarioData = await scenarioResponse.json();
        console.log('Scenarios:', allScenarioData);
        setScenarios(allScenarioData);
      }
      loadScenarioList();
      clearExistingGameData();
    } catch (e) {
      console.log('Error clearing existing game data. Game might not be fully reset.');
      console.error(e);
    }
  }, []);

  async function startGameRequest(scenario) {
    // dev endpoint
    const url = ' https://api.prod.swag-dispenser.smb.aws.dev/main-menu/start-game';
    const response = await fetch(url,
      {
        method: 'POST',
        body: JSON.stringify({
          'eventID': 'c303282d-f2e6-46ca-a04a-35d3d873712d',
          'gameID': scenario
        })
      }
    );
    if (response.ok) {
      const data = await response.json();
      setGameMetadata(data);
      return data;
    } else {
      console.log("Error with game start response", response);
      throw new Error('Failed to start game - response error');
    }
  }

  async function getChallengesForScenario(scenario) {
    const gameSequence = [];
    // load all challenge json for selected scenario
    for (let i = 0; i < scenarios[scenario]['challenges'].length; i++) {
      const challengeResponse = await fetch(`/config/${scenarios[scenario]['challenges'][i]}`)
      const challengeData = await challengeResponse.json();
      gameSequence.push(challengeData);
    }
    console.log('Challenges loaded for scenario: ', gameSequence);
    const gameStage = {
      scenario,
      challenge: 0,
      page: 0
    };

    setGameSequence(gameSequence);
    setGameStage(gameStage);
  }

  async function handleStartGame(scenario) {
    try {
      console.log(`Selected game scenario: ${scenario}`);
      console.log("Starting new game");
      const gameStarted = await startGameRequest(scenario);
      console.log('game response', gameStarted);
      await getChallengesForScenario(scenario);
      navigate('/game/challenge');
    } catch (error) {
      console.error(error);
      console.log('Error starting game');
    }
  }

  function ScenarioList() {
    return (
      <div className='scenario_list'>
        {
          Object.keys(scenarios).map((scenario, index) => {
            return (
              <div key={scenario}
                onClick={() => { handleStartGame(scenario) }}
              >
                <div className='scenario_image'>
                  <img src={`/images/${scenarios[scenario].icon_image}`}/>
                </div>
                <div className='scenario_title'>
                  {scenarios[scenario].title}
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <div id='scenario_select_page' className='page_container'>
      <ResetGameButton />
      <h1>Choose an industry:</h1>
      <ScenarioList />
    </div>
  );
}

export default ScenarioSelectPage;