import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { moveToNextChallengePage, getPiiProtectionScore, setPiiProtectionScore, getDeniedTopicsScore, setDeniedTopicsScore } from '../../utils/utils';
import SmallCharacterBubble from '../../components/SmallCharacterBubble';
import PromptContainer from '../../components/PromptContainer';
import ResultModal from '../../components/ResultModal';
import ResetGameButton from '../../components/ResetGameButton';

function Challenge3McPage(props) {
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  // add selected state to options
  useEffect(() => {
    let transformedOptions = [];
    props.options.forEach((option, index) => {
      const optionObj = {
        ...option,
        selected: false,
      }
      transformedOptions.push(optionObj);
    });
    setOptions(transformedOptions);
  }, [props.options]);


  function handleOptionClick(index) {
    let newOptions = [...options];
    // reset all options to unselected, then only select current one
    newOptions.forEach((option) => { option.selected = false });
    newOptions[index].selected = true;
    setOptions(newOptions);
    setCurrentOption(newOptions[index]);
  }


  function validateAndScoreAnswer(option) {
    let setter;
    let getter;

    if  (props.choiceType === 'piiProtection') {
      setter = setPiiProtectionScore;
      getter = getPiiProtectionScore;
    } else if (props.choiceType === 'deniedTopics') {
      setter = setDeniedTopicsScore;
      getter = getDeniedTopicsScore;
    }

    let currentScore = getter();
    if (currentScore === null) {
      currentScore = props.startingPoints;
    }

    if (!option.correct) {
      setModalSuccess(false);
      currentScore = currentScore - props.pointPenalty;
    } else {
      setModalSuccess(true);
    }
    setter(currentScore);
    setDisplayModal(true);
  }

  function handleSubmit() {
    validateAndScoreAnswer(currentOption);
  }

  function handleModalClose() {
    setDisplayModal(false);
  }

  function OptionsList() {
    return (
      <ul className='multiple_choice_list'>
        {
          options.map((option, index) => {
            return (
              <li key={index}
                onClick={() => handleOptionClick(index)}
                className={option.selected ? 'selected_green' : ''}
              >
                <h3>{option.title}</h3>
                {option.text}
              </li>
            )
          })
        }
      </ul>
    )
  }

  
  return (
    <div id='extended_multiple_choice_page' className='page_container'>
      <ResetGameButton />
      <ResultModal
        display={displayModal}
        message={modalSuccess ? props.correctMessage : props.retryMessage}
        onClick={() => { modalSuccess ? moveToNextChallengePage() : handleModalClose() }}
      />

      <SmallCharacterBubble 
        characterImage={props.characterImage}
        characterSpeech={props.characterSpeech}
      />

      <PromptContainer label='Prompt'>
        {
          props.prompt.map((promptSection, index) => {
            return (
              <p className={promptSection.highlighted ? 'prompt_highlight' : ''}>
                {promptSection.text}
              </p>
            )
          })
        }
      </PromptContainer>

      <h3>Select how to best protect data:</h3>
      <OptionsList />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        disabled={options.filter((option) => option.selected).length === 0}
        onClick={() => { handleSubmit() }}
        sx={{
          mt: 3,
          mb: 2,
          fontSize: '1.2rem',
        }}
      >
        Select Option
      </Button>
    </div>
  );
}

export default Challenge3McPage;