import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import ScenarioSelectPage from './pages/ScenarioSelectPage';
import ChallengeManager from './pages/ChallengeManager';

import './App.css';

function App() {
  return (
    <div id='app_container'>
      <Router>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="game/select-scenario" element={<ScenarioSelectPage />} />
          <Route path="game/challenge" element={<ChallengeManager />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;