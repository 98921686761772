export function getGameSequence() {
  return JSON.parse(localStorage.getItem('gameSequence'));
}

export function setGameSequence(gameSequence) {
  localStorage.setItem('gameSequence', JSON.stringify(gameSequence));
}

export function getGameStage() {
  return JSON.parse(localStorage.getItem('gameStage'));
}

export function setGameStage(gameStage) {
  localStorage.setItem('gameStage', JSON.stringify(gameStage));
}

export function getGameMetadata() {
  return JSON.parse(localStorage.getItem('gameMetadata'));
}

export function setGameMetadata(gameMetadata) {
  localStorage.setItem('gameMetadata', JSON.stringify(gameMetadata));
}

export function getMultipleChoiceAnswers() {
  let answers = JSON.parse(localStorage.getItem('multipleChoiceAnswers'));
  if (answers === null) {
    answers = {};
  }
  return answers;
}

export function setMultipleChoiceAnswer(answerType, answerText) {
  let answers = getMultipleChoiceAnswers();
  answers[answerType] = answerText;
  localStorage.setItem('multipleChoiceAnswers', JSON.stringify(answers));
}

export function getPromptInjectionErrorScore() {
  return JSON.parse(localStorage.getItem('promptInjectionErrorScore'));
}

export function setPromptInjectionErrorScore(score) {
  localStorage.setItem('promptInjectionErrorScore', JSON.stringify(score));
}

export function getPromptInjectionFixScore() {
  return JSON.parse(localStorage.getItem('promptInjectionFixScore'));
}

export function setPromptInjectionFixScore(score) {
  localStorage.setItem('promptInjectionFixScore', JSON.stringify(score));
}

export function getPiiProtectionScore() {
  return JSON.parse(localStorage.getItem('piiProtectionScore'));
}

export function setPiiProtectionScore(score) {
  localStorage.setItem('piiProtectionScore', JSON.stringify(score));
}

export function getDeniedTopicsScore() {
  return JSON.parse(localStorage.getItem('deniedTopicsScore'));
}

export function setDeniedTopicsScore(score) {
  localStorage.setItem('deniedTopicsScore', JSON.stringify(score));
}

export function getLastChallengeResponse() {
  return JSON.parse(localStorage.getItem('lastChallengeResponse'));
}

export function setLastChallengeResponse(response) {
  localStorage.setItem('lastChallengeResponse', JSON.stringify(response));
}

async function endCurrentGame(gamePlayId) {
   // dev endpoint
   const url = 'https://api.prod.swag-dispenser.smb.aws.dev/core-gameplay/end-game';
   const response = await fetch(url,
     {
       method: 'POST',
       body: JSON.stringify({
         'gamePlayID': gamePlayId,
       })
     }
   );
   if (response.ok) {
     const data = await response.json();
     console.log('Game ended', data);
   } else {
     console.log("Error with game end response", response);
   }
} 

export function moveToNextChallengePage() {
  if (advanceGameStage()) {
    console.log('Moving to next challenge page')
    window.location.href = '/game/challenge';
  } else {
    let gameMetadata = getGameMetadata();
    endCurrentGame(gameMetadata.gamePlay.gamePlayID);
    window.location.href= 'https://api.prod.swag-dispenser.smb.aws.dev/post-game/show-score?gamePlayID=' + gameMetadata.gamePlay.gamePlayID;
  }
}

export function clearExistingGameData() {
  localStorage.removeItem('multipleChoiceAnswers');
  localStorage.removeItem('lastChallengeResponse');
  localStorage.removeItem('promptInjectionErrorScore');
  localStorage.removeItem('promptInjectionFixScore');
  localStorage.removeItem('piiProtectionScore');
  localStorage.removeItem('deniedTopicsScore');
}

export function advanceGameStage() {
  let stage = getGameStage();
  let sequence = getGameSequence();

  // Advance challenge page
  if (sequence[stage.challenge].length - 1 > stage.page) {
    stage.page += 1;
    setGameStage(stage);
    return true;
  } else {
    // Advance challenge itself
    if (sequence.length - 1 > stage.challenge) {
      stage.challenge += 1;
      stage.page = 0;
      setGameStage(stage)
      return true;
    } else {
      // End game
      return false
    }
  }
}